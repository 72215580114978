<template>
  <div class="check-page">
    <!-- {{batchId}} -->
    <div class="box">
      <div class="top-img">
        <img width="70%" src="../../assets/checklogo1.png" alt="">
      </div>
      <el-input class="search" v-model="code">
        <template slot="append">
          <!-- 查询 -->
          <el-button type="primary" size="small" @click="handleSearch"
            >查询</el-button
          >
        </template>
      </el-input>
      <span class="search"
        >如何获取防伪编码，您可以在商品外包装上找到防伪贴纸，刮开涂层，获得4位防伪编码</span
      >
      <div v-if="show">
        <!-- 查询结果 -->
        <div class="finaly">
          <span class="tip">查询结果</span>
          <br>
          <div class="content">
              <img src="../../assets/template.png" alt="">
              <div class="text">
                  <div >
                      <el-icon v-if="isCheck ==1" style="font-size:30px;margin-right:10px" class="el-icon-circle-check"></el-icon>
                      <el-icon v-if="isCheck ==2" style="font-size:30px;margin-right:10px" class="el-icon-circle-close"></el-icon>
                      <el-icon v-if="isCheck ==3" style="font-size:30px;margin-right:10px" class="el-icon-warning-outline"></el-icon>
                  </div>
                  <div>
                      {{content}}
                  </div>
              </div>
          </div>
        </div>
      </div>
      <el-divider class="divider"></el-divider>
      <div v-if="show" class="info">
          <p v-if="indexNo!=null ">溯源号码：{{indexNo}}</p>
          <p v-if="proName!=null">产品名称：{{proName}} </p>
          <p v-if="auditOrg!=null">企业名称：{{auditOrg}} </p>
      </div>
    </div>
    <div class="bottom">
      <p>{{work}} </p>
      <img width="100px" src="../../assets/qr.jpg" alt="" />
      <br />
      <span>扫码获取更多帮助</span>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      batchId: localStorage.getItem('batchId'),
      indexNo: '',
      code: null,
      show:false,
      isCheck:'',
      content:'',
      work:'',
      auditOrg:'',
      proName:'',
    };
  },
  mounted() {
    // console.log(this.batchId);
    this.$axios({
        url:'/show/findContext',
        method:'get',
        params:{
            indexNo:this.indexNo
        }
    }).then((res)=>{
        if(res.data.retCode=='0000'){
            this.work = res.data.retData
        }
    })
  },
  methods: {
    handleSearch() {
      this.auditOrg=''
      let isIndex = this.$route.query.noIndex
      let params={
        batchId: this.batchId,
        verifyCode: this.code,
      }
      if(!isIndex){
        params.indexNo = localStorage.getItem('indexNo')
      }
      this.$axios({
        url: "/show/findCode",
        method: "get",
        params: params,
      }).then((res) => {
        // console.log(res);
        if(res.data.retCode==='0000'){
            const {result,auditOrg,proName,indexNo} = res.data.retData
            this.content = result
            let arr=[]
            if(this.content.indexOf('首次')>0){
                this.isCheck = 3
            }else if(this.content.indexOf('无效')>0){
                this.isCheck =2
            } else{
                this.isCheck =1
            }
            // console.log(auditOrg.indexOf(']'));
            if(auditOrg && auditOrg.indexOf(']')>0){
                arr = JSON.parse(auditOrg)
                // console.log(arr);
                for (let i = 0; i < arr.length; i++) {
                    const element = arr[i];
                    if(i==arr.length-1){
                        this.auditOrg+=element
                    }else{
                        this.auditOrg+=element+','
                    }
                }
            }else{
                this.auditOrg = auditOrg
            }
            this.proName = proName?proName:null
            this.indexNo = indexNo?indexNo:null
            // console.log(this.proName,this.auditOrg,this.indexNo);
            this.show = true
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.check-page {
  min-height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  // justify-content:center;
  align-items:center;
  margin: 0 auto;
  padding-top: 20px;
//   text-align: center;
}
.top-img {
//   margin-top: 20px;
  // height: 70px;
  text-align: center;
}
.box {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  // flex: 3;
  // min-height: 100%;
  height: 500px;
}

.search {
//   width: 80%;
  margin-bottom: 10px;
}
.finaly {
    width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  .tip{
      color: orange;
  }
  .content{
    //   padding: 2px;
    // width: 100%;
    margin: 0 auto;
      color: #dbdbdb;
      background-color: rgba(0, 0, 0, 0);
      img{
          width: 100%;
      }
      .text{
          width: 70%;
          padding: 0 20px;
          position: absolute;
          margin-top: -56px;
          font-size: 14px;
          display: flex;
      }
  }
}
.divider {
//   width: 80%;
  height: 2px;
  color: black;
  background-color: rgba(0, 0, 0, 0.432);
}
.info{
    font-size: 14px;
    width: 100%;
    text-align: left;
}
.bottom {
  margin-top: 10px;
  text-align: center;
  padding: 0 5px;
  font-size: 14px;
  span {
    font-size: 0.1rem;
  }
}
</style>